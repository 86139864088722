import React, { Component } from 'react';

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var profilepic= "images/"+this.props.data.image;
      var city = this.props.data.address.city;
      var country = this.props.data.address.country;
      var phone= this.props.data.phone;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;


      var bios = this.props.data.about.map(function(bios){
        return <div key={bios.heading} className="about-blurb">
           <h3>{bios.heading}</h3>
               <p>{bios.text}</p>
           </div>
      })

    }
/*
    return (
      <section id="about">
      <div className="row">
         <div className="three columns">
            <img className="profile-pic"  src={profilepic} alt="George Pechtol Profile Pic" />
         </div>
         <div className="nine columns main-col">
            <h2>About Me</h2>
           {bios}

            <div className="row">
               <div className="columns contact-details">
                  <h2>Contact</h2>
                  <p className="address">
						   <span>{name}</span><br />
						         {city}, {country}<br />
                     <span>{email}</span>
					   </p>
               </div>
               
               <div className="columns download">
                  <p>
                     <a href={resumeDownload} className="button"><i className="fa fa-download"></i>Download Resume</a>
                  </p>
               </div>

            </div>
         </div>
      </div>
*/

return (
   <section id="about">
   <div className="row">
      <div className="three columns">
         <img className="profile-pic"  src={profilepic} alt="George Pechtol Profile Pic" />
      </div>
      <div className="nine columns main-col">
         <h2>About Me</h2>
        {bios}

         <div className="row">
            <div className="columns contact-details">
               <h2>Contact</h2>
               <p className="address">
                  <span>{name}</span><br />
                        {city}, {country}<br />
                  <span>{email}</span>
               </p>
            </div>
            </div>
      </div>
   </div>

      <p className="scrolldown">
         <a className="smoothscroll" href="#portfolio"><i className="icon-down-circle"></i></a>
      </p>

   </section>
    );
  }
}

export default About;
