import React, { Component } from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import SVGIcon from "./svgicons";

class Portfolio extends Component {
  render() {
    if (this.props.data) {
      var projects = this.props.data.projects.map(function (projects) {
        var projectImage = "images/portfolio/" + projects.image;
        return (
          <div key={projects.title} className="columns portfolio-item">
            <div className="item-wrap">
              <a href={projects.url} title={projects.title}>
                <img alt={projects.title} src={projectImage} />
                <div className="overlay">
                  <div className="portfolio-item-meta">
                    <h5>{projects.title}</h5>
                    <p>{projects.category}</p>
                  </div>
                </div>
                <div className="link-icon">
                  <i className="fa fa-link"></i>
                </div>
              </a>
            </div>
          </div>
        );
      });

      var timelineElements = this.props.data.timeline.map(function (element) {
        var thumbnail = "/images/timeline/" + element.thumbnail + ".jpg";

        let designStyle = { background: "#ffb70f" };
        let mobileStyle = { background: "#ff750f" };
        let websiteStyle = { background: "#11abb0" };
        let intranetStyle = { background: "#2ea8e6" };
        let conferenceStyle = { background: "#094ab2" };
        let printStyle = { background: "#5e3d96" };

        var iconstyle = websiteStyle;
        var icontype = "";

        switch (element.type) {
          case "website":
            iconstyle = websiteStyle;
            break;
          case "intranet":
            iconstyle = intranetStyle;
            break;
          case "design":
            iconstyle = designStyle;
            break;
          case "mobile":
            iconstyle = mobileStyle;
            break;
          case "conference":
            iconstyle = conferenceStyle;
            break;
          case "print":
            iconstyle = printStyle;
            break;
          default:
            iconstyle = websiteStyle;
        }

        return (
          <VerticalTimelineElement
            key={element.id}
            date={element.date}
            dateClassName="date"
            iconStyle={iconstyle}
            icon={<SVGIcon name={element.type} width={200} />}
          >
            <div>
              <div className="floatright">
                <img
                  className="thumbnail"
                  src={thumbnail}
                  alt={element.title}
                />
              </div>

              <div>
                <h3 className="vertical-timeline-element-title">
                  {element.title}
                </h3>
                <h5 className="vertical-timeline-element-subtitle">
                  {element.client}
                </h5>
                <p className="description">{element.description}</p>
              </div>
            </div>
          </VerticalTimelineElement>
        );
      });
    }

    return (
      <section id="portfolio">
        <div className="row">
          <h2>Timeline of Work</h2>
          <VerticalTimeline>{timelineElements}</VerticalTimeline>
        </div>
      </section>
    );
  }
}

export default Portfolio;
