import React, { Component } from "react";
import { Parallax, Background } from "react-parallax";

class Triheader extends Component {
  render() {
    if (this.props.data) {
      var bannerswim = this.props.data.bannerswim;
      var bannerbike = this.props.data.bannerbike;
      var bannerrun = this.props.data.bannerrun;
    }

    return (
      <section id="tri">
        <div id="triheader">
          <Parallax bgImage={bannerswim} strength={300}>
            <div style={{ height: 600 }}>
              <h2>swim</h2>
            </div>
          </Parallax>

          <Parallax bgImage={bannerbike} strength={300}>
            <div style={{ height: 600 }}>
              <h2>bike</h2>
            </div>
          </Parallax>

          <Parallax bgImage={bannerrun} strength={300}>
            <div style={{ height: 600 }}>
              <h2>run</h2>
            </div>
          </Parallax>
        </div>
      </section>
    );
  }
}

export default Triheader;
